export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_eHGbMuofl",
    "USER_POOL_APP_CLIENT_ID": "2cl43q96bq13iuvkfm3e8ke68s",
    "ROOT_API_URL": "https://1zxxa4wcuc.execute-api.us-east-1.amazonaws.com/exchange-prod-documents-api-prod",
    "MFA_ISSUER": "Precedent-exchange-prod",
    "S3_DEPLOYMENT_BUCKET": "exchange-prod-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:db3097b2-9d60-4329-93c0-9feaaade7326",
    "RUM_APP_MONITOR_ID": "7bec17b7-91a7-44d0-8daf-dee7bca60b5c",
    "ENV_STAGE": "prod"
}
